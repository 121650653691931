.textCentered {
  text-align: center;
}

.closeIcon {
  cursor: pointer;
}

.closeIcon:hover {
  background-color: rgb(255, 200, 200);
  /* border-radius: 50%; */
}

.ui_window {
  border: 1px solid gray;
  /* width: 600px; */
  /* height: 300px; */
  border-radius: 3px;
  background-color: white;
  border: 2px solid #a2d4ff;
}

.window_handle {
  cursor: move;
  background-color: #8088ff;
  display: flex;
  justify-content: space-between;
}

.window_content {
  padding: 5px;
}

.hover_emboss {
  cursor: pointer;
}

.hover_emboss:hover {
  cursor: pointer;
  scale: 1.1;
}

/* excel sheet */

table {
  border: none;
  cursor: pointer;
}

.sheet_cell {
  border-bottom: 1px solid rgba(170, 170, 170, 0.5);
  border-right: 1px solid rgba(170, 170, 170, 0.5);
  padding: 2px 4px;
}

.sheet_head_cell {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  padding: 2px 4px;
  white-space: nowrap;
}

.sheet_cell:hover {
  background-color: #bec2ff;
}
/* 
.sheet_head_cell {
  border-top: 1px solid black;
  border-right: 1px solid black;
} */

.chat_window_wrapper {
  border: 1px solid black;
  border-radius: 3px;
  background-color: white;
}

.chat_icon_wrapper {
  display: flex;
  border: 1px solid #8088ff;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(200, 0, 0, 0.4);
  animation: custom_pulse 1s infinite;
}

.chat_icon {
  font-size: 2.5em !important;
  display: contents !important;
}

i.red.icon {
  color: #8088ff !important;
}

.taskbar_close {
  color: white !important;
}

@keyframes custom_pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(128, 136, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 20px rgba(128, 136, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(128, 136, 255, 0);
  }
}

.react-draggable {
  position: absolute !important;
}

.margin-0 {
  margin: 0 !important;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #f0f0f0;
}

.navbar_options {
  line-height: 35px;
}

.navbar_options:hover {
  border-bottom: inset 3px white;
  cursor: pointer;
}

.landing_images {
  width: 100px;
  margin: 0 0 10px 0;
}

.textInput {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.product_name {
  color: whitesmoke;
  opacity: 0.8;
  text-align: center;
  font-size: 6rem;
  padding-top: 20%;
  position: relative;
}

.product_name::before {
  position: absolute;
  content: "AutoEDA";
  transform: translate(0px, 5px);
  z-index: -1;
}

.product_name::after {
  position: absolute;
  content: "AutoEDA";
  z-index: -1;
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px;
}

.product_name::before {
  color: #e91e63;
  animation: distort1 200ms linear infinite;
}

.product_name::after {
  color: #03a9f4;
  animation: distort2 200ms linear infinite;
}

@keyframes distort1 {
  0% {
    transform: translateY(-5px);
    transform: translateX(-5px);
  }

  12.5% {
    transform: translateY(-5px);
    transform: translateX(-3px);
  }

  25% {
    transform: translateY(-5px);
    transform: translateX(7px);
  }

  37.5% {
    transform: translateY(5px);
    transform: translateX(7px);
  }

  50% {
    transform: translateY(5px);
    transform: translateX(7px);
  }

  62.5% {
    transform: translateY(5px);
    transform: translateX(-3px);
  }

  75% {
    transform: translateY(5px);
    transform: translateX(-5px);
  }

  87.5% {
    transform: translateY(5px);
    transform: translateX(-5px);
  }

  100% {
    transform: translateY(-5px);
    transform: translateX(-5px);
  }
}

@keyframes distort2 {
  0% {
    transform: translateY(5px);
    transform: translateX(7px);
  }

  12.5% {
    transform: translateY(5px);
    transform: translateX(7px);
  }

  25% {
    transform: translateY(-5px);
    transform: translateX(7px);
  }

  37.5% {
    transform: translateY(-5px);
    transform: translateX(-3px);
  }

  50% {
    transform: translateY(-5px);
    transform: translateX(-5px);
  }

  62.5% {
    transform: translateY(5px);
    transform: translateX(-5px);
  }

  75% {
    transform: translateY(5px);
    transform: translateX(-5px);
  }

  87.5% {
    transform: translateY(5px);
    transform: translateX(-3px);
  }

  100% {
    transform: translateY(5px);
    transform: translateX(7px);
  }
}
